@import "../bower_components/normalize.css/normalize";
@import "bootstrap/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.row{
  &:after, &:before{
    display: inline-block;
  }
}

@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 100;
  src: local("ProximaNova"), local("ProximaNova"),
  url("/fonts/ProximaNovaThin.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 200;
  src: local("ProximaNova"), local("ProximaNova"),
  url("/fonts/ProximaNovaLight.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 300;
  src: local("ProximaNova"), local("ProximaNova"),
  url("/fonts/ProximaNovaRegularIt.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 500;
  src: local("ProximaNova"), local("ProximaNova"),
  url("/fonts/ProximaNovaSemibold.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 600;
  src: local("ProximaNova"), local("ProximaNova"),
  url("/fonts/ProximaNovaBold.ttf") format("truetype");
}
/*Aeroport*/
@font-face {
  font-family: "Aeroport";
  font-style: normal;
  font-weight: 100;
  src: local("Aeroport"), local("Aeroport"),
  url("/fonts/Aeroport-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Aeroport";
  font-style: normal;
  font-weight: 300;
  src: local("Aeroport"), local("Aeroport"),
  url("/fonts/Aeroport.ttf") format("truetype");
}
@font-face {
  font-family: "Aeroport";
  font-style: normal;
  font-weight: 400;
  src: local("Aeroport"), local("Aeroport"),
  url("/fonts/AeroportMono.ttf") format("truetype");
}
@font-face {
  font-family: "Aeroport";
  font-style: normal;
  font-weight: 500;
  src: local("Aeroport"), local("Aeroport"),
  url("/fonts/Aeroport-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Aeroport";
  font-style: normal;
  font-weight: 600;
  src: local("Aeroport"), local("Aeroport"),
  url("/fonts/Aeroport-Bold.ttf") format("truetype");
}

.clear{
  clear: both;
}
*{
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-weight: 300;
  font-family: 'Aeroport', sans-serif;
}
a,button{
  &:hover{
    text-decoration: none;
  }
}
input:focus{
  &::placeholder{
    opacity: 0;
  }
}
button:focus,button:active,input:focus,input:active{
  outline: none!important;
}


.container-fluid {
  overflow: hidden;
}

ul.slick-dots{
  position: absolute;
  right: 0;
  top: 0;
  height: 0;
  width: 0;
  opacity: 0;
}

button.slick-arrow{
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 0;
  height: 0;
}
.slick-slide:focus{
  outline: none;
}
.container{
  max-width: 1300px;
  padding-left: 30px;
  padding-right: 30px;
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background: #eee;
}
body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #EC258F;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
}

.grey{
  display: table;
  font-family: ProximaNova;
  font-weight: 300;
  font-size: 18px;
  color: #BFBFBF;
  margin: 0 0 5px;
}
h1,h2,h3,h4,h5,h6{
  color: #EC258F;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.1;
  margin: 0 0 10px;
  font-family: Aeroport;
  span{
    color: #EC258F;
    font-weight: 600;
    font-size: 36px;
  }
}
.defaultText{
  display: table;
  margin: 0 0 12px;
  line-height: 1.6;
  color: #4E527B;
  font-family: ProximaNova;
  font-weight: 300;
  span{
    display: table;
    font-weight: 600;
    color: #EC258F;
  }
}
.btnDefault{
  display: table;
  padding: 8px 20px 9px;
  border: 1px solid #EC258F;
  color: #EC258F;
  border-radius: 30px;
  font-weight: 500;
  background: #fff;
  &.pink{
    background: #EC258F;
    color: #fff;
  }
}
.arrowsBlock{
  position: relative;
  width: 100%;
  .buttonArrows{
    position: absolute;
    display: table;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    background: #E22488;
    color: #fff!important;
    transition: .2s linear;
    i{
      display: table-cell;
      vertical-align: middle;
    }
    &:hover{
      background: #D992B8;
      cursor: pointer;
    }
    &.left{
      left: 0;
    }
    &.right{
      right: 0;
    }
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  .container{
    padding-top: 15px;
    img.logo{
      display: table;
      height: 76px;
    }
    ul.menu{
      display: table;
      width: 100%;
      margin: 26px 0 0;
      text-align: center;
      li{
        display: inline-table;
        margin: 0 26px 0 0;
        &:last-child{
          margin: 0;
        }
        a{
          display: table;
          color: #747474;
          font-weight: 300;
          margin: 0;
        }
      }
    }
    ul.control{
      display: table;
      width: 100%;
      margin: 20px 0 0;
      text-align: right;
      li{
        display: inline-table;
        margin: 0 0 0 28px;
        vertical-align: middle;
        a.phone{
          display: table;
          margin: 0;
          color: #747474;
          img{
            display: inline-table;
            height: 20px;
            margin: 0 10px 0 0;
          }
        }
        button{
          background: transparent;
        }
        &:first-child{
          margin: 0;
        }
      }
    }
    .line{
      display: table;
      margin-top: 15px;
      width: 100%;
      border-bottom: 1px solid #ebebeb;
    }
  }
  &.fixed{
    background: #fff;
  }
}

.fatherBlock{
  position: relative;
  width: 100%;
  overflow: hidden;
}
.absoluteImg{
  position: absolute;
}

.firstBlock{
  position: relative;
  padding: 140px 0 330px;
  background: url("/img/bkgfirst.png")no-repeat;
  background-size: cover;
  background-position: right bottom;
  .container{
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    img.ice{
      position: absolute;
      display: table;
      right: -4%;
      top: -5%;
      height: 130%;
    }
    p.mini{
      display: table;
      margin: 0 0 14px;
      color: #D26FA4;
      font-size: 18px;
      font-weight: 100;
    }
    h1{
      display: table;
      font-size: 92px;
      margin: 0 0 34px;
      line-height: 1;
    }
  }
  img.scrollDown{
    position: absolute;
    left: 50%;
    bottom: 90px;
    width: 160px;
    margin-left: -80px;
  }
}

.aboutBlock{
  position: relative;
  padding: 0;
  img.bkgAbsol{
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    object-fit: cover;
    object-position: top left;
  }
  .container{
    padding-top: 120px;
    position: relative;
    padding-bottom: 200px;
    .row{
      position: relative;
      z-index: 2;
    }
    .grey{
      margin-top: 140px;
    }
    h2{
      margin-bottom: 20px;
    }
    .defaultText{
      width: 80%;
    }
    .aboutSlick{
      width: 70%;
      margin: 0 auto;
      z-index: 2;
      position: relative;
      .block{
        display: table;
        width: 100%;
        position: relative;
        img.main{
          display: table;
          width: 100%;
          margin: 0 auto;
          height: 600px;
          object-fit: cover;
          object-position: center;
          border-radius: 4px;
        }
        a{
          position: absolute;
          top: 26px;
          right: 26px;
          background: #fff;
          border: none;
          border-radius: 50%;
          img{
            height: 36px;
          }
        }
      }
    }
    .backSlider{
      position: absolute;
      z-index: 0;
      left: 5%;
      top: 15%;
      width: 50%;
      height: 80%;
      transform: rotate(-20deg);
      .aboutSlick{
        width: 100%;
        height: 100%;
        .block{
          img{
            height: 100%;
          }
          button{
            display: none;
          }
        }
      }
    }
    .arrowsBlock{
      position: absolute;
      width: 75%;
      z-index: 3;
      top: 50%;
      left: 12.5%;
      margin-top: -20px;
    }
  }
  .abs1{
    right: 0;
    top: -15%;
    height: 45%;
  }
  .abs2 {
    top: 0;
    right: 15%;
  }

  .abs3 {
    left: 90%;
    top: 0;
  }

  .abs4 {
    bottom: -24px;
    left: 28%;
    z-index: 3;
  }
  img.absoluteImg.line {
    bottom: 12%;
    left: 0;
    height: 400px;
    z-index: 0;
  }
  img.absoluteImg.bus {
    bottom: 0;
    left: 75%;
    height: 380px;
  }
}

.franshizaBlock{
  position: relative;
  width: 100%;
  padding: 0;
  background: #fffdfa;
  .container{
    padding-top: 40px;
    padding-bottom: 100px;
    .defaultText{
      margin-bottom: 30px;
    }
    h2{
      margin-bottom: 28px;
    }
    .blockFra{
      display: table;
      position: relative;
      width: 100%;
      border-radius: 4px;
      padding: 20px 26px;
      overflow: hidden;
      background: #fff;
      transition: .2s linear;
      padding-bottom: 100px;
      img.head{
        display: table;
        margin: 0 0 20px;
        height: 60px;
      }
      p.name{
        display: table;
        margin: 10px 0 16px;
        font-size: 20px;
        font-weight: 600;
        color: #4E527B;
        transition: .2s linear;
      }
      p.descr{
        display: table;
        line-height: 1.6;
        width: 95%;
        color: #4E527B;
        min-height: 210px;
      }
      .bottom{
        position: absolute;
        bottom: 0;
        left: 0;
        display: table;
        width: 100%;
        padding: 0 26px 40px;
        a.more{
          display: table;
          font-weight: 600;
          color: #D992B8;
          i{
            margin-left: 15px;
          }
        }
        a.phone{
          transition: .2s linear;
          opacity: 0;
          position: absolute;
          right: -16px;
          bottom: -16px;
          padding: 42px 48px 48px 42px;
          border-radius: 50%;
          background: #EC258F;
          img{
            height: 24px;
          }
        }
      }
      &:hover{
        box-shadow: 0px 61px 80px rgba(115, 10, 110, 0.07), 0px 1.88474px 15.1373px rgba(115, 10, 110, 0.035);
        p.name{
          color: #EC258F;
        }
        a.more{
          color: #EC258F;
        }
        a.phone{
          opacity: 1;
        }
      }
    }

    .absoluteImg.left{
      right: 100%;
      top: 35%;
      height: 180px;
    }
    .absoluteImg.right{
      left: 100%;
      top: -15%;
      height: 150px;
    }
  }

  .callBackForm{
    display: table;
    margin: 100px 0 0;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    background: #FFECF8;
    position: relative;
    border: 2px solid #FFD5EA;
    padding: 40px 50px;
    p.name{
      display: table;
      margin: 0 0 14px;
      font-size: 24px;
      font-weight: 600;
      color: #EC258F;
    }
    p.descr{
      display: table;
      margin: 0;
      line-height: 1.5;
      color: #4E527B;
      font-family: ProximaNova;
      font-weight: 300;
      width: 80%;
    }
    .absoluteImg{
      right: 0;
      bottom: 0;
      height: 100%;
    }
    .btnDefault{
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

}

.galleryBlock{
  position: relative;
  width: 100%;
  img.bkgAbsol{
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    object-fit: cover;
    object-position: bottom left;
  }

  .container{
    padding-top: 40px;
    padding-bottom: 100px;
    h3{
      display: table;
      margin: 0 auto 20px;
    }
    .defaultText{
      display: table;
      margin: 0 auto 20px;
      text-align: center;
    }
  }
  .arrowsBlock{
    position: absolute;
    width: 32%;
    top: 50%;
    left: 34%;
  }
  .gallerySlick{
    margin: 60px 0 0;
    width: 100%;
    .slick-list{
      overflow: visible;
    }
    .slick-slide{
      margin: 0 10px;
      padding: 40px 0;
      transition: .2s linear;
      .block{
        display: table;
        width: 100%;
        position: relative;
        img.main{
          display: table;
          width: 100%;
          margin: 0 auto;
          height: 460px;
          object-fit: cover;
          object-position: center;
          border-radius: 4px;
          transition: .2s linear;
        }
        a{
          position: absolute;
          top: 26px;
          right: 26px;
          background: #fff;
          border: none;
          border-radius: 50%;
          opacity: 0;
          transition: .2s linear;
          img{
            height: 36px;
          }
        }
      }
      &.slick-active{
        padding: 0;
        img.main{
          height: 540px;
        }
        &.slick-center {
          padding: 40px 0;
          img.main{
            height: 460px;
          }
          a{
            opacity: 1;
          }
        }
      }
    }
  }
}

.dealerBlock{
  width: 100%;
  position: relative;
  img.bkgAbsol{
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60%;
    object-fit: cover;
    object-position: top left;
  }
  .container{
    padding-top: 80px;
    padding-bottom: 60px;
    .inBlock{
      display: table;
      width: 100%;
      border-radius: 10px;
      background: #EC258F;
      border: 2px solid #FF81D4;
      padding: 40px 50px;
      position: relative;
      margin-bottom: 150px;
      &:before{
        content: '';
        position: absolute;
        top: -80px;
        left: -100px;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background: #B90062;
        opacity: 0.05;
      }
      &:after{
        content: '';
        position: absolute;
        bottom: 10%;
        left: 110%;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #B90062;
        opacity: 0.05;
      }
      p.name{
        display: table;
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        margin: 0 0 20px;
      }
      p.descr{
        display: table;
        margin: 10px 0 0;
        line-height: 1.6;
        color: #fff;
        width: 90%;
        font-family: ProximaNova;
        font-weight: 200;
      }
      img{
        display: table;
        width: 130%;
        margin: -25% 0 0 -15%;
      }
      button{
        display: table;
        margin: -45px auto 0;
      }
    }
    h3{
      margin-bottom: 20px;
      margin-top: 24px;
    }
    img.cakeImg{
      display: table;
      width: 95%;
    }
    p.defaultText{
      width: 90%;
      margin-bottom: 16px;
    }
    a.callBack{
        display: table;
        font-weight: 600;
      margin: 22px 0 0;
        color: #EC258F;
        i{
          margin-left: 15px;
        }
      }
  }
}

.productsBlock{
  position: relative;
  width: 100%;
  padding: 0;
  background: #FFF9FC;
  .container{
    padding-bottom: 60px;
    position: relative;
    *{
      z-index: 2;
      position: relative;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 80%;
      width: 550px;
      height: 550px;
      border-radius: 50%;
      background: #B90062;
      opacity: 0.05;
      z-index: 0;
    }
  }
  .download{
    display: table;
    font-weight: 600;
    margin: 14px 0 80px;
    color: #EC258F;
    i{
      margin-left: 15px;
    }
  }
  .row.big{
    width: 110%;
    margin-left: -5%;
  }
  .block{
    display: table;
    width: 100%;
    position: relative;
    box-shadow: 0px 20px 50px rgba(115, 10, 110, 0.03), 0px 1.88474px 10px rgba(115, 10, 110, 0.035);
    .head{
      position:relative;
      width: 100%;
      display: table;
      padding: 40px 20px;
      background: #FFE5F3;
      border-radius: 5px 5px 0 0;
      p.name{
        display: table;
        margin: 0;
        font-size: 18px;
        color: #EC258F;
        font-weight: 600;
      }
      img{
        position: absolute;
        bottom: 0;
        right: 0;
        height: 120%;
      }
    }
    .body{
      display: table;
      width: 100%;
      padding: 40px 20px;
      background: #fff;
      border-radius: 0 0 5px 5px;
      p.descr{
        display: table;
        line-height: 1.6;
        margin: 0 0 28px;
        color: #4E527B;
        min-height: 150px;
      }
      a{
        display: table;
        font-weight: 600;
        margin: 0;
        color: #EC258F;
        i{
          margin-left: 15px;
        }
      }
    }
  }
}

.deliveryBlock{
  position: relative;
  width: 100%;
  background: #FFF9FC;
  .container{
    padding-top: 60px;
    padding-bottom: 100px;
  }
  img.bigMain{
    display: table;
    width: 90%;
  }
  h4{
    margin-bottom: 24px;
    margin-top: 50px;
  }
  .defaultText{
    margin-bottom: 24px;
  }
  .block{
    position: relative;
    background: transparent;
    border-radius: 8px;
    overflow: hidden;
    padding: 15px;
    transition: .2s linear;
    margin: 0 0 15px;
    img.icon{
      display: inline-table;
      width: 50px;
      height: 50px;
      vertical-align: middle;
    }
    p{
      display: inline-table;
      width: 65%;
      line-height: 1.6;
      color: #4e527b;
      margin: 0 0 0 20px;
      vertical-align: middle;
    }
    a{
      transition: .2s linear;
      display: table;
      position: absolute;
      right: 0;
      height: 100%;
      top: 0;
      padding: 28px 14px 0;
      background: #EC258F;
      opacity: 0;
      img{
        height: 18px;
      }
    }
    &:hover{
      background: #fff;
      box-shadow: 0px 40px 80px rgba(115, 10, 110, 0.07), 0px 1.88474px 10px rgba(115, 10, 110, 0.035);
      a{
        opacity: 1;
      }
    }
  }
}

footer {
  background: #FFFDFA;
  .container{
    padding-top: 60px;
    padding-bottom: 40px;
    img.logo2{
      display: table;
      height: 80px;
    }
    ul.menu{
      display: table;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      li{
        display: inline-table;
        margin: 0 26px 0 0;
        &:last-child{
          margin: 0;
        }
        a{
          display: table;
          color: #4E527B;
          font-weight: 300;
          margin: 0;
        }
      }
    }
    ul.control{
      display: table;
      width: 100%;
      margin: 28px auto 0;
      text-align: center;
      li{
        display: inline-table;
        margin: 0 0 0 28px;
        vertical-align: middle;
        a.phone{
          display: table;
          margin: 0;
          color: #747474;
          img{
            display: inline-table;
            height: 20px;
            margin: 0 10px 0 0;
          }
        }
        button{
          background: transparent;
        }
        &:first-child{
          margin: 0;
        }
      }
    }
    .line{
      display: table;
      width: 100%;
      margin: 40px 0 30px;
      border: 1px solid #EC258F;
    }
    a.copyright{
      display: table;
      margin: 0;
      color: #4E527B;
      font-weight: 300;
    }
    ul.social{
      display: table;
      float: right;
      margin: 0;
      li{
        display: inline-table;
        margin: 0 0 0 22px;
        a{
          display: table;
          margin: 0;
          i{
            display: table;
            font-size: 16px;
            color: #EC258F;
          }
        }
      }
    }
  }
}



@media(max-width: 1500px){

}

@media(max-width: 1025px){
  header .container ul.control {
    margin: 0;
  }

  header .container ul.control li {
    margin: 5px 0;
  }

  header .container ul.menu {
    margin: 0;
    width: 80%;
    float: right;
  }

  header .container ul.menu li {
    margin: 5px 15px 5px 0;
  }

  .firstBlock .container img.ice {
    height: 130%;
    right: 0;
    top: 0;
  }

  .firstBlock .container p.mini {
    font-size: 16px;
  }

  .firstBlock .container h1 {
    font-size: 64px;
  }

  .firstBlock .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .firstBlock {
    padding-bottom: 200px;
  }

  .firstBlock img.scrollDown {
    bottom: 50px;
  }

  header .container img.logo {
    height: 55px;
  }

  .btnDefault {
    padding: 5px 15px;
  }

  .aboutBlock .container {
    padding-top: 30px;
  }

  .aboutBlock .abs1 {
    top: 0;
  }

  .aboutBlock .container .aboutSlick .block img.main {
    height: 400px;
  }

  .aboutBlock .container .aboutSlick .block img.main {
  }

  .aboutBlock .container .backSlider {
    height: 40%;
  }

  .aboutBlock .container .grey {
    margin-top: 20px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 24px;
  }

  .aboutBlock .container .defaultText {
    width: 100%;
  }

  .franshizaBlock .container {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .franshizaBlock .container .blockFra {
    padding: 10px;
  }

  .franshizaBlock .container .blockFra p.name {
    font-size: 18px;
  }

  .franshizaBlock .container .blockFra p.descr {
    width: 100%;
    min-height: auto;
  }

  .franshizaBlock .container .blockFra .bottom {
    position: relative;
    padding: 0 0 15px;
  }

  .franshizaBlock .container .blockFra .bottom a.phone {
    bottom: -40px;
    right: -40px;
  }

  .franshizaBlock .container .blockFra .bottom a.more {
    width: 60%;
  }

  .franshizaBlock .callBackForm {
    margin-top: 40px;
  }

  .franshizaBlock .callBackForm {
    padding: 30px;
  }

  .franshizaBlock .callBackForm .absoluteImg {
    width: 50%;
    object-fit: cover;
    object-position: left top;
  }

  .franshizaBlock .callBackForm .row {
    position: relative;
    z-index: 2;
  }

  .galleryBlock .gallerySlick {
    margin: 20px 0 0;
  }

  .galleryBlock .gallerySlick .slick-slide.slick-active img.main {
    height: 300px;
  }

  .galleryBlock .gallerySlick .slick-slide {
    padding: 20px 0;
  }

  .galleryBlock .gallerySlick .slick-slide.slick-active.slick-center img.main {
    height: 260px;
  }

  .galleryBlock .gallerySlick .slick-slide.slick-active.slick-center {
    padding: 20px 0;
  }

  .galleryBlock .gallerySlick .slick-slide .block img.main {
    height: 260px;
  }

  .galleryBlock .gallerySlick .slick-slide.slick-active img.main {
    height: 300px;
  }

  .dealerBlock .container {
    padding-top: 0;
  }

  .dealerBlock .container .inBlock:before {
    top: 0;
  }

  .dealerBlock .container .inBlock {
    padding: 20px 30px;
  }

  .dealerBlock .container .inBlock p.name {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .dealerBlock .container .inBlock p.descr {
    width: 100%;
  }

  .dealerBlock .container .inBlock img {
    margin: 0;
    width: 100%;
  }

  .dealerBlock .container .inBlock button {
    margin: 0 auto;
  }

  .dealerBlock .container p.defaultText {
    width: 100%;
  }

  .productsBlock .download {
    margin-bottom: 40px;
  }

  .productsBlock .row.big .col-sm-3 {
    flex: 0 50%;
    max-width: 50%;
  }

  .productsBlock .row.big {
    width: 100%;
    margin: 0;
  }

  .productsBlock .block {
    margin: 0 0 30px;
  }

  .deliveryBlock .container {
    padding-top: 0;
    padding-bottom: 30px;
  }

  .deliveryBlock h4 {
    margin: 0 0 14px;
  }

  .deliveryBlock .block p {
    margin: 0;
    display: table;
    width: 80%;
  }

  .deliveryBlock .block img.icon {
    height: 35px;
    width: 35px;
    margin: 0 0 10px;
  }

  .deliveryBlock .block a {
    padding-top: 15px;
  }

  footer .container {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .aboutBlock .container .backSlider .aboutSlick .block img {
    height: 100%;
  }
}
@media(max-width: 1025px) and (min-width: 679px) {

}
@media(max-width: 769px){

}
@media(max-width: 678px){
  header {}

  .container {
    padding-right: 10px;
    padding-left: 10px;
  }

  header .container ul.menu {
    position: fixed;
    top: 78px;
    right: 0;
    background: #fff;
    width: 50%;
    border-radius: 0 0 5px 5px;
    display: table;
    padding: 5px 0;
    display: none;
  }

  header .container ul.control {
    display: none;
  }

  header .container .line {
    display: none;
  }

  header .container ul.menu li {
    display: table;
    margin: 15px 0!important;
    width: 100%;
    text-align: right;
  }

  header .container ul.menu li a {
    width: 100%;
    padding: 0 20px;
  }

  .firstBlock {
    padding-top: 100px;
    padding-bottom: 190px;
  }

  .firstBlock .container img.ice {
    height: 140px;
    top: 40%;
  }

  .firstBlock .container h1 {
    font-size: 36px;
  }

  .firstBlock .container p.mini {
    font-size: 14px;
    width: 60%;
  }

  .firstBlock img.scrollDown {
    height: 120px;
    bottom: 40px;
  }

  .aboutBlock .container {
    padding-top: 0;
    padding-bottom: 170px;
  }

  .aboutBlock .container .backSlider .aboutSlick .block img {
    height: 100%;
  }

  .aboutBlock .container .aboutSlick .block img.main {
    height: 350px;
  }

  .aboutBlock .container .backSlider .aboutSlick .block img {
    height: 100%;
  }

  .aboutBlock {
    margin-top: -20px;
  }

  .aboutBlock .container .grey {
    margin-top: 40px;
    text-align: center;
    width: 100%;
  }

  .aboutBlock img.absoluteImg.line {
    top: 15%;
    height: 200px;
  }

  .aboutBlock .container h2 {
    text-align: center;
  }

  .aboutBlock .container .defaultText {
    text-align: center;
  }

  .aboutBlock img.absoluteImg.bus {
    left: 0;
    height: 160px;
    width: 100%;
  }

  .franshizaBlock .container {
    padding-top: 50px;
  }

  .franshizaBlock {}

  .grey {
    font-size: 14px;
  }

  .franshizaBlock .container h2 {
    margin-bottom: 15px;
  }

  .franshizaBlock .container .blockFra .bottom {
    margin-top: 50px;
  }

  .franshizaBlock .container .blockFra {
    margin-bottom: 15px;
  }

  .franshizaBlock .callBackForm {
    margin-top: 15px;
    padding: 15px;
  }

  .franshizaBlock .callBackForm .btnDefault {
    position: relative;
    margin: 25px 0 10px;
  }

  .franshizaBlock .callBackForm .absoluteImg {
    height: 100%;
  }

  .franshizaBlock .callBackForm p.name {
    font-size: 20px;
    width: 70%;
  }

  .galleryBlock {
    padding: 0;
  }

  .galleryBlock .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .galleryBlock .container .defaultText {
    margin-bottom: 0;
  }

  .galleryBlock .arrowsBlock {
    bottom: 30%;
    top: unset;
  }

  .galleryBlock .gallerySlick .slick-slide.slick-active.slick-center {
    padding: 0;
    width: 100%;
  }

  .galleryBlock .gallerySlick .slick-slide {
    padding: 0!important;
  }

  .dealerBlock .container .inBlock {
    padding: 15px 20px;
    margin-bottom: 60px;
  }

  .dealerBlock {
    padding: 0;
  }

  .galleryBlock .gallerySlick .slick-slide {
    margin: 0;
  }

  .galleryBlock .gallerySlick .slick-list {
    overflow: hidden;
  }

  .dealerBlock .container .inBlock img {
    margin: 15px 0 0;
  }

  .dealerBlock .container .inBlock button {
    margin-bottom: 15px;
  }

  .dealerBlock .container img.cakeImg {
    margin: -20px auto -40px;
    width: 60%;
  }

  .dealerBlock .container h3 {
    margin-bottom: 10px;
  }

  .productsBlock .row.big .col-sm-3 {
    flex: 100%;
    max-width: 100%;
  }

  .productsBlock .row.big {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
    width: auto;
  }

  .productsBlock .block .body {
    padding: 20px;
  }

  .productsBlock .block .body p.descr {
    margin: 0 0 15px;
  }

  .productsBlock .container {
    padding-bottom: 20px;
  }

  .deliveryBlock img.bigMain {
    width: 100%;
    margin-bottom: 20px;
  }

  .deliveryBlock {
    padding: 0;
  }

  .deliveryBlock .block a {
    position: relative;
    height: auto;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    padding: 5px 0;
    margin: 15px 0 0;
    opacity: 1;
  }

  .deliveryBlock .col-sm-6 {
    flex: 0 50%;
    max-width: 50%;
  }

  .deliveryBlock .block p {
    width: 100%;
    line-height: 1.3;
    font-size: 12px;
    min-height: 50px;
    height: 0;
  }

  .deliveryBlock .block {
    background: #fff;
  }

  .deliveryBlock .container {
    padding-bottom: 20px;
  }

  footer .container {
    padding: 20px 10px;
  }

  footer .container img.logo2 {
    height: 40px;
    margin: 0 auto;
  }

  footer .container ul.menu {
    display: none;
  }

  footer .container ul.control {
    margin: 24px 0 0;
  }

  footer .container .line {
    margin: 30px 0;
  }

  footer .container a.copyright {
    width: 70%;
  }

  footer .container ul.social {
    margin-top: -25px;
  }

  footer .container ul.control li {
    margin: 0 8px;
  }

  footer {
    position: relative;
    overflow: hidden;
  }
  a.d-block.d-sm-none.burger {
    position: absolute;
    right: 20px;
    top: 12px;
    background: #fff;
    color: #ec258f;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 3px;
    display: table!important;
    box-shadow: 0 0 13px rgba(236,37,143,0.1);
    &:focus{
      box-shadow: 0 0 13px rgba(236,37,143,0.5);
    }
  }

  a.d-block.d-sm-none.burger i {
    display: table-cell;
    vertical-align: middle;
  }
  header{
    transition: .2s linear;
  }
  header .container {padding: 10px;}
  header.fixed {
    box-shadow: 0 0 13px rgba(0,0,0,0.15);
  }
}